import React, { useState } from "react";
import {
    Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";


import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme)=>({
    link:{
        textDecoration:"none",
        color: "#000",
        fontSize: "20px",
    },
    icon:{
        color: "#000",
        width:'10px'
    },
}));

function DrawerComponent() {
    const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  


  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)} className="navbar"
      >
        <List>

        <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/" className={classes.link}>HOME</Link>
            </ListItemText>
          </ListItem>
          <Divider/>

        <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/otherclasses" className={classes.link}>CLASSES</Link>
            </ListItemText>
          </ListItem>
          <Divider/>

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/location" className={classes.link}>LOCATION</Link>
            </ListItemText>
          </ListItem>
          <Divider/>

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/newsblogs" className={classes.link}>BLOG</Link>
            </ListItemText>
          </ListItem>
          <Divider/>
          
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/contactpage" className={classes.link}>CONTACT US</Link>
            </ListItemText>
          </ListItem>
          {/* <Divider/> */}
          
          
        </List>

        


      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}className={classes.icon}>
        <MenuIcon />
      </IconButton>
    </>
  );
}
export default DrawerComponent;
